import React from 'react'
import Moment from 'react-moment'

import { Link } from "gatsby"
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function About({ latestData, locale }) {


  const sortedLatestBlog = latestData.sort((a, b) => b.created_at - a.created_at).reverse();
  const getData = sortedLatestBlog.slice(0, 5);
  return (
    <>
      <div className="psection3">
        <div className="psection3Cont"> 
          <div className="psection3Img" />
          <div className="psection3Abt">
            <Slider>
              {getData.map((node, index) =>
                <div className="psection3AbtCont" key={index}>
                  <div className="psection3AbtTitle">
                    {node.node.title}
                  </div>
                  <Link to={`/resource/blog/${node.node.slug}/`}> <div className="psection3AbtHeading">
                    {node.node.heading}
                  </div></Link>
                  <div className="psection3AbtDes"
                  dangerouslySetInnerHTML={{ __html: node.node.preview}} />
                  <div className="date_listing">
                    <div className="date"><Moment format='MMMM Do YYYY'>{node.node.date}</Moment></div>
                    <div className="time">{node.node.time}</div>
                  </div>
                </div>
              )}

            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
