import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from "gatsby"

import FeatureBlog from '../featureblog/FeatureBlog'
import Moment from 'react-moment'
const CloudNews = ({ locale, heading, blogList, slug }) => {
  const data = useStaticQuery(graphql`
    query{
      allStrapiCloudnews {
            edges {
              node {
                locale
                created_at
                slug
                date
                time
                title
                heading
                industryList {
                  value
                }
                technologyList {
                  value
                }
                description
                id
                cloudnewsimg {
                  localFile {
                    childImageSharp {
                      fixed {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
      }
      
    `).allStrapiCloudnews.edges
  const getData = data.filter((node) => node.node.locale === locale)

  // Array of all Cloud News
  const allNews = getData


  //
  // State for the list
  const [dropdownvalue, setDropdownvalue] = useState({ technology: "Technology", industry: "Industry" })
  const [list, setList] = useState([])
  const handlefilter = () => dropdownvalue.technology === "Technology" && dropdownvalue.industry === "Industry" ?
    allNews
    : dropdownvalue.industry === "Industry" ?
      allNews.filter((val) => val.node.technologyList.value === dropdownvalue.technology)
      : dropdownvalue.technology === "Technology" ? allNews.filter((val) => val.node.industryList.value === dropdownvalue.industry) :
        allNews.filter((val) => val.node.industryList.value === dropdownvalue.industry && val.node.technologyList.value === dropdownvalue.technology)


  useEffect(() => {
    const updatedData = handlefilter();
    setList([...updatedData.slice(0, 3)]);
  }, [dropdownvalue])
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 3)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const allNewsFilter = handlefilter();

      const isMore = currentLength < allNewsFilter.length
      const nextResults = isMore
        ? allNewsFilter.slice(currentLength, currentLength + 3)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  //Check if there is more
  useEffect(() => {

    const allNewsFilter = handlefilter();
    const isMore = list.length < allNewsFilter.length
    setHasMore(isMore)
  }, [list])
  return (
    <>
      <FeatureBlog values={slug} locale={locale} latestData={getData ? getData : []} />
      <div className="cloud_industry">
        <h1>{heading}</h1>
        <div className="select_box_success">
          <select onChange={(e) => setDropdownvalue({ ...dropdownvalue, industry: e.target.value })}>
            {blogList.industry.map((val, index) => <option key={index}>
              {val}
            </option>)}
          </select>
          <select className="last_select" onChange={(e) => setDropdownvalue({ ...dropdownvalue, technology: e.target.value })} >
            {blogList.technology.map((val, index) => <option key={index}>
              {val}
            </option>)}
          </select>
        </div>
        <div className="blogsCards">
          {list.map((blog, index) => {
            return (
              <Link to={`/resource/cloud-news/${blog.node.slug}/`} key={index}>
                <div className="blogCard" style={{ marginBottom: "20px" }}>
                  <div className="blog_img"><Img fixed={blog.node.cloudnewsimg.localFile.childImageSharp.fixed} alt="blog" className="blogsCardImage" /></div>
                  <div className="successStoryTitle">{blog.node.title}</div>
                  <div className="successStoryHeading">{blog.node.heading}</div>
                  <div className="resouce_content">{blog.node.description}</div>
                  <div className="date_listing">
                    <div className="date"><Moment format='MMMM Do YYYY'>{blog.node.date}</Moment></div>
                    <div className="time">{blog.node.time}</div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
        {hasMore ? (<div className="center_btn"> <button className="successStoryBtn" onClick={handleLoadMore}>View More</button></div>) : null}
      </div>
    </>
  )
}

export default CloudNews
