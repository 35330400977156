import React from "react";
import ReactPlayer from "react-player";
import { Modal } from 'react-bootstrap'
import video from "../components/video/opsioVideo.mp4"
function Player({ open, toggleModal }) {
    return (
        <Modal
            show={open}
            onHide={toggleModal}
            centered={true}
            dialogClassName="modal-50w"
        >
            <Modal.Header closeButton style={{ border: "none" }}>
            </Modal.Header>
            <ReactPlayer
                url={video}
                width="100%"
                playing
                controls
            />
        </Modal>
    );

}

export default Player;
