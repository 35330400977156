import React from 'react'
import { Link } from 'gatsby';

function HeadingNavigation(
    {
        data
    }
) {
    return (
        <div className="main_sectionParent">
        
        <div className="main_sectionLatest">
          <div className="latest_news">

            <div className="psection1 mt-4 mt-md-0">
                <div className="psection1Title">
                    {data.title}
                </div>
                <h1 className="psection1Heading">
                    {data.heading}
                </h1>
            </div>
        </div>
        <div className="pNavSection2">
            <div className="pNav">
                <ul className="pNav-ul">
                    {/* <li key={1} onClick={(e) => handleClick("cloud-news")} className={data.slug === "cloud-news" ? 'active':''}>{data.menubar.l1} </li>
                    <li key={2} onClick={(e) => handleClick("press-and-media")} className={data.slug === "press-and-media"?'active':''}>{data.menubar.l2}</li> */}
                    <Link to={`/resource/blog/`} key={3} className={data.slug === "blog" ? 'active':''}>
                        <li>
                            {data.menubar.l3}
                        </li>
                    </Link>
                    <Link to={`/resource/white-papers/`} key={4} className={data.slug === "white-papers"?'active':''}>
                        <li>
                            {data.menubar.l4}
                        </li>
                    </Link>
                </ul>
            </div>
            </div>
        </div>
        </div>
    )
}

export default HeadingNavigation
