import React from "react";
import Layout from "../components/layout";
import Resource from "../components/resource";
import { graphql } from "gatsby";
import SEO from "../components/seo";
function MainResourcePage({ data }) {
  const getData = data.allStrapiResourceMainSections.edges[0].node;
  const slug = `resource/${getData.slug}`;

  return (
    <Layout slug={slug} locale={getData.locale}>
      <SEO
        noindexForce={false}
        slug={slug}
        locale={getData.locale}
        title={getData.MetaTitle}
        description={getData.MetaDes}
      />
      <Resource data={getData && getData} />
    </Layout>
  );
}

export default MainResourcePage;
export const query = graphql`
  query MainResourcePage($slug: String!, $locale: String!) {
    allStrapiResourceMainSections(
      filter: { slug: { eq: $slug }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          locale
          heading
          menubar {
            l1
            l2
            l3
            l4
          }
          blogDropDown {
            all
            byDate
            industry
            popularBlogs
            technology
            trending
          }
          heading2
          title2
          title
          MetaTitle
          MetaDes
        }
      }
    }
  }
`;
