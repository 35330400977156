import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import { graphql, Link, useStaticQuery } from "gatsby"
import { Modal } from 'react-bootstrap'

import FeatureBlog from '../featureblog/FeatureBlog'
import Slider from "react-slick";
import Moment from 'react-moment';
import MediaQuery from "react-responsive";
import Player from '../../../components/video';
const baseUrl = "https://s3.amazonaws.com/static.neostack.com/img/react-slick"

const PressMedia = ({ locale, blogList, heading, slug, title }) => {



    //MODAL BOX HANDLERS

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [openVideoModal, setOpenVideoModal] = useState(false)
    ////////////////////


    const data = useStaticQuery(graphql`
    query{
        allStrapiPressmedias {
            edges {
              node {
                locale
                slug
                date
                created_at
                time
                title
                all
                heading
                description
                id
                pressmediaimg {
                  localFile {
                    childImageSharp {
                      fixed {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
          allStrapiPhotoGalleries {
            edges {
              node {
                locale
                date
                time
                title
                image {
                  localFile {
                    childImageSharp {
                      fixed {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
      }
    
`)
    const getPhotoGallery = data.allStrapiPhotoGalleries.edges.filter((node) => node.node.locale === locale)
    const pressMediaBlogData = data.allStrapiPressmedias.edges;
    const getData = pressMediaBlogData.filter((node) => node.node.locale === locale)
    const allNews = getData
    const dateArray = getData.map((data) => data.node.date);
    var uniqueDateArray = Array.from(new Set(dateArray));
    const [dropdownvalue, setDropdownvalue] = useState({ all: 'All', date: "By Date" })

    // State for the list
    const [list, setList] = useState([])
    const handlefilter = () => dropdownvalue.all === "All" && dropdownvalue.date === "By Date" ?
        allNews
        : dropdownvalue.all === "All" ?
            allNews.filter((val) => val.node.date === dropdownvalue.date)
            : dropdownvalue.date === "By Date" ? allNews.filter((val) => val.node.all === dropdownvalue.all) :
                allNews.filter((val) => val.node.date === dropdownvalue.date && val.node.all === dropdownvalue.all)
    useEffect(() => {
        const updatedData = handlefilter();
        setList([...updatedData.slice(0, 3)]);
    }, [dropdownvalue])
    // State to trigger Load more
    const [loadMore, setLoadMore] = useState(false)

    // State of whether there is more to load
    const [hasMore, setHasMore] = useState(allNews.length > 3)

    // Load more button click
    const handleLoadMore = () => {
        setLoadMore(true)
    }

    // Handle loading more articles
    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const allNewsFilter = handlefilter();
            const isMore = currentLength < allNewsFilter.length
            const nextResults = isMore
                ? allNewsFilter.slice(currentLength, currentLength + 3)
                : []
            setList([...list, ...nextResults])
            setLoadMore(false)
        }
    }, [loadMore, hasMore])
    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <Img fixed={getPhotoGallery[i].node.image.localFile.childImageSharp.fixed} />
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    //Check if there is more
    useEffect(() => {
        const allNewsFilter = handlefilter();
        const isMore = list.length < allNewsFilter.length
        setHasMore(isMore)
    }, [list])
    return (
        <>
         <FeatureBlog values={slug} locale={locale} latestData={getData ? getData : []} />
            <div className="cloud_industry">
            <div className="blog_tab_title">{title}</div>
                <h1>{heading}</h1>
                <div className="select_box_success">
                    <select onChange={(e) => setDropdownvalue({ ...dropdownvalue, date: e.target.value })}>
                        <option>
                            By Date
                        </option>
                        {uniqueDateArray.map((val, index) => <option key={index}>
                            {val}
                        </option>)}
                    </select>
                    <select onChange={(e) => setDropdownvalue({ ...dropdownvalue, all: e.target.value })}>
                        {blogList.all.map((val, index) => <option key={index}>
                            {val}
                        </option>)}
                    </select>
                </div>
                <div className="blogsCards">
                    {list.map((blog, index) => {
                        return (
                            <Link to={`/resource/press-and-media/${blog.node.slug}/`} key={index} >
                                <div className="blogCard" style={{ marginBottom: "20px" }}>
                                    <div className="blog_img"><Img fixed={blog.node.pressmediaimg.localFile.childImageSharp.fixed} alt="blog" className="blogsCardImage" /></div>
                                    <div className="successStoryTitle">{blog.node.title}</div>
                                    <div className="successStoryHeading">{blog.node.heading}</div>
                                    <div className="resouce_content">{blog.node.description}</div>
                                    <div className="date_listing">
                                        <div className="date"><Moment format='MMMM Do YYYY'>{blog.node.date}</Moment></div>
                                        <div className="time">{blog.node.time}</div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
                {hasMore ? (<div className="center_btn"> <button className="successStoryBtn" onClick={handleLoadMore}>View More</button></div>) : null}

            </div>
            <div className="mediaRelease">
                <div className="media_inner">
                    <div className="mediaReseaseHeading">Media Releases</div>
                    <div className="mrsec">
                        <div className="mrsec-left mediaInnerStr" onClick={handleShow}>
                            <div className="mediaContent">
                                <div className="eventTitle">
                                    Event
                                </div>
                                <div className="mediaDes">
                                    Opsio Cloud Meetup 2020 at <br></br>
                                    Karlstad (Sweden)
                                </div>
                                <div className="date">
                                    May 8th, 2020
                                </div>
                            </div>
                        </div>
                        <div className="mrsec-right  mediaInnerStr">
                            <div onClick={() => setOpenVideoModal(true)} className="play_icon" style={{ backgroundImage: `url(${require("../../../images/play.svg").default})` }}></div>
                            <div className="mediaContent">
                                <div className="eventTitle">
                                    About us
                                </div>
                                <div className="mediaDes">
                                    Glimpse of Opsio Work
                                </div>
                                <div className="date">
                                    May 8th, 2020
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} animation={false} centered={true} size='lg' contentClassName="modal-pressmedia" id="gallery_modal">
                        <Modal.Header closeButton style={{ border: "none" }}>
                        </Modal.Header>
                        <Modal.Body style={{ border: "none" }}>
                            <div>
                                <Slider {...settings}>
                                    {getPhotoGallery.map((img, index) => <div key={index}>
                                        <Img fixed={img.node.image.localFile.childImageSharp.fixed} />
                                    </div>)}
                                </Slider>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
                <div className="px-lg-4" style={{ marginTop: '60px' }}>
                    <div className="photoGalleryHeading">Photo Gallery</div>
                    <div className="select_box_success photoGallerySelect d-flex justify-content-center pt-lg-4">
                        <select >
                            <option>
                                By Date
                            </option>
                        </select>
                    </div>

                    <div className="blogsCards">
                        {getPhotoGallery.map((blog, index) => {
                            return (
                                <div className="blogCard" style={{ marginBottom: "20px" }} key={index}>
                                    <div className="blog_img"><Img fixed={blog.node.image.localFile.childImageSharp.fixed} alt="blog" className="blogsCardImage" /></div>
                                    <div className="successStoryTitle">{blog.node.title}</div>
                                    <div className="date_listing">
                                        <div className="date"><Moment format='MMMM Do YYYY'>{blog.node.date}</Moment></div>
                                        <div className="time">{blog.node.time}</div>
                                    </div>
                                </div>
                            )
                        })}

                        <div className="photo_center_btn"> <button className="successStoryBtn">View More</button></div>
                    </div>
                </div>

            <Player open={openVideoModal} toggleModal={() => setOpenVideoModal(false)} />
        </>
    )
}

export default PressMedia
