import React from 'react'
import { useState, useEffect } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import FeatureBlog from '../featureblog/FeatureBlog'
import Moment from 'react-moment'
import { Link } from "gatsby"
const Blog = ({ locale, blogList, title, heading, slug }) => {
  
  const queryData = useStaticQuery(graphql`
    query{
        allStrapiBlogs(sort: {fields: date, order: ASC}) {
            edges {
              node {
                locale
                slug
                created_at
                date
                time
                title
                heading
                preview
                industryList {
                  value
                }
                technologyList {
                  value
                }
                serviceList {
                  value
                }
                id
                blogimg {
                  localFile {
                    childImageSharp {
                      fixed {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }

          allStrapiServiceLists {
            edges {
              node {
                value
                locale
              }
            }
          }
      }
      
    `);
    
  const data = queryData.allStrapiBlogs.edges
  const serviceData = ['Service', ...queryData.allStrapiServiceLists.edges?.
  filter((node) => node.node.locale === locale)?.
  map((node) => node.node.value)];
    
  const getData = data.filter((node) => node.node.locale === locale)
  // Array of all Cloud News
  const allNews = getData

  // State to trigger Load more
  const [loadMore, setLoadMore] = useState(false);
  const [dropdownvalue, setDropdownvalue] = useState({ technology: "Technology", industry: "Industry", service: "Service" });

  const [list, setList] = useState([])
  // State of whether there is more to load

  // This filter is based on possible conditions
  const handlefilter = () => {
    let filtered = allNews;

    if (dropdownvalue.technology !== "Technology") {
      filtered = filtered.filter((val) => val.node.technologyList?.value === dropdownvalue.technology);
    }

    if (dropdownvalue.industry !== "Industry") {
      filtered = filtered.filter((val) => val.node.industryList?.value === dropdownvalue.industry);
    }

    if(dropdownvalue.service !== "Service") {
      filtered = filtered.filter((val) => val.node.serviceList?.value === dropdownvalue.service)
    }

    return filtered;

  }
  useEffect(() => {
    const updatedData = handlefilter();
    setList([...updatedData.slice(0, 3)]);
  }, [dropdownvalue])
  const [hasMore, setHasMore] = useState(allNews.length > 3)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const allNewsFilter = handlefilter();
      const isMore = currentLength < allNewsFilter.length
      const nextResults = isMore
        ? allNewsFilter.slice(currentLength, currentLength + 3)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  //Check if there is more
  useEffect(() => {
    const allNewsFilter = handlefilter();
    const isMore = list.length < allNewsFilter.length
    setHasMore(isMore)
  }, [list])


  return (
    <>
      <FeatureBlog values={slug} locale={locale} latestData={getData ? getData : []} />
      <div className="cloud_industry">
        <div className="blog_tab_title">{title}</div>
        <h3 className="blogSectionHeading">{heading}</h3>
        <div className="select_box_success">

          <select className="last_select" onChange={(e) => setDropdownvalue({ ...dropdownvalue, industry: e.target.value })}>
            {blogList.industry.map((val, index) => <option key={index}>
              {val}
            </option>)}
          </select>
          <select className="last_select" onChange={(e) => setDropdownvalue({ ...dropdownvalue, technology: e.target.value })}>
            {blogList.technology.map((val, index) => <option key={index}>
              {val}
            </option>)}
          </select>
          <select className="last_select" onChange={(e) => setDropdownvalue({ ...dropdownvalue, service: e.target.value })}>
            {serviceData.map((val, index) => <option key={index}>
              {val}
            </option>)}
          </select>
        </div>
        <div className="blogsCards">
          {list.map((blog, index) => {
            return (
              <Link to={`/resource/blog/${blog.node.slug}/`} key={index}>
                <div className="blogCard" style={{ marginBottom: "20px" }} >
                  <div className="blog_img">
                    <Img fixed={blog.node.blogimg[0]?.localFile.childImageSharp.fixed} alt="blog" className="blogsCardImage object-contain" />
                    </div>
                  <div className="successStoryTitle">{blog.node.title}</div>
                  <div className="successStoryHeading" style={{ width: "338px" }}>{blog.node.heading}</div>
                  <div className="resouce_content" dangerouslySetInnerHTML={{ __html:blog.node.preview}} />
                  <div className="date_listing">
                    <div className="date"><Moment format='MMMM Do YYYY'>{blog.node.date}</Moment></div>
                    <div className="time">{blog.node.time}</div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
        {hasMore ? (<div className="center_btn"> <button className="successStoryBtn" onClick={handleLoadMore}>View More</button></div>) : null}
      </div>
    </>
  )
}

export default Blog
