import React from 'react'
import HeadingNavigation from './herosection/HeadingNavigation'
import '../../styles/resource.scss'
import Blogs from "../resource/blog/blog"
import PressMedia from './pressmedia/pressmedia'
import CloudNews from './cloudnews/cloudnews'
import Whitepaper from './whitepaperblog'
function Resource({ data }) {
    return (
        <div>
            <HeadingNavigation data={data} />
            {data.slug === "white-papers" && <Whitepaper locale={data.locale} />}
            {data.slug === "blog" && <Blogs slug={data.slug} locale={data.locale} blogList={data.blogDropDown} title={data.title2} heading={data.heading2} />}
            {data.slug === "press-and-media" && <PressMedia slug={data.slug} locale={data.locale} title={data.title2} blogList={data.blogDropDown} heading={data.heading2} />}
            {data.slug === "cloud-news" && <CloudNews slug={data.slug} locale={data.locale} blogList={data.blogDropDown} heading={data.heading2} />}
        </div>
    )
}

export default Resource
